html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
}

body {
    position: relative;
}

fieldset {
    border: none;
}
