#root {
  /*display: flex;*/
  min-height: 100%;
}

.app {
  flex: 1;
  /* background: #333; */
}

a {
  text-decoration: none;
}

.body-content {
  min-height: 100%;
  margin: 0 auto;
  /* background: #ffffff; */
}

.alert-dialog {
  min-width: 300px;
}

.navbar-toggler {
  background-color: whitesmoke;
}

*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: rgba(15, 34, 0, 0.05);
}

* {
  -webkit-overflow-scrolling: touch;
}
*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: rgba(15, 34, 0, 0.05);
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 50px;
}
*::-webkit-scrollbar-corner {
  background-color: rgba(15, 34, 0, 0.05);
}
