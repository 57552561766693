.success {
    color: #4CAF50;
}

.flex {
    display: flex;
}

.flex-1{
    flex: 1;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.text-center {
    text-align: center;
}

.large-input {
    font-size: 14px;
    line-height: 14px;
    height: 24px;
    border-radius: 4px;
}

.margin-left-20px {
    margin-left: 20px !important;
}

.margin-right-20px {
    margin-right: 20px !important;
}

.margin-top-20px {
    margin-top: 20px !important;
}

.margin-bottom-20px {
    margin-bottom: 20px !important;
}